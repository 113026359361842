import React, { Suspense, lazy } from "react";
import {
  Navigate,
  Route,
  Routes as Switch,
  useLocation,
} from "react-router-dom";
import { useAppContext } from "./libs/contextLib";

import Home from "./containers/Home";
import { CircularProgress } from "@material-ui/core";
import DocumentTitle from "react-document-title";

const Box = lazy(() => import("./containers/Box"));
const NotFound = lazy(() => import("./containers/NotFound"));
const Login = lazy(() => import("./containers/Login"));
const EditBox = lazy(() => import("./containers/EditBox"));
const Profile = lazy(() => import("./containers/Profile"));
const StripeOnboardRefresh = lazy(() =>
  import("./containers/StripeOnboardRefresh")
);
const Privacy = lazy(() => import("./containers/Privacy"));
const Terms = lazy(() => import("./containers/Terms"));
const About = lazy(() => import("./containers/About"));
const Group = lazy(() => import("./containers/Group"));
const QRScan = lazy(() => import("./containers/QRScan"));

export default function Routes() {
  const { isAuthenticated } = useAppContext();
  const location = useLocation();

  const PrivateRoute = ({ children }) => {
    return isAuthenticated ? (
      children
    ) : (
      <Navigate
        replace
        to={{ pathname: "/login", state: { from: location.pathname } }}
      />
    );
  };

  return (
    <Suspense
      fallback={
        <center>
          <CircularProgress />
        </center>
      }
    >
      <Switch>
        <Route
          exact
          path="/"
          element={
            <DocumentTitle title="Honesty Box">
              <Home />
            </DocumentTitle>
          }
        />
        <Route
          exact
          path="/scan"
          element={
            <DocumentTitle title="QR Scan">
              <QRScan />
            </DocumentTitle>
          }
        />
        <Route
          exact
          path="/login"
          element={
            <DocumentTitle title="Login">
              <Login />
            </DocumentTitle>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <PrivateRoute>
              <DocumentTitle title="Profile">
                <Profile />
              </DocumentTitle>
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/group/:groupId"
          element={
            <PrivateRoute>
              <DocumentTitle title="Manage Group">
                <Group />
              </DocumentTitle>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/group/:groupId/create"
          element={
            <PrivateRoute>
              <DocumentTitle title="Create Group">
                <EditBox />
              </DocumentTitle>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/group/:groupId/:boxId/edit"
          element={
            <PrivateRoute>
              <DocumentTitle title="Edit Box">
                <EditBox />
              </DocumentTitle>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/group/:groupId/refresh"
          element={
            <PrivateRoute>
              <DocumentTitle title="Stripe Refresh">
                <StripeOnboardRefresh />
              </DocumentTitle>
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/about"
          element={
            <DocumentTitle title="About">
              <About />
            </DocumentTitle>
          }
        />
        <Route
          exact
          path="/privacy"
          element={
            <DocumentTitle title="Privacy">
              <Privacy />
            </DocumentTitle>
          }
        />
        <Route
          exact
          path="/terms"
          element={
            <DocumentTitle title="Terms">
              <Terms />
            </DocumentTitle>
          }
        />

        <Route
          exact
          path="/:boxId"
          element={
            <DocumentTitle title="View Box">
              <Box />
            </DocumentTitle>
          }
        />

        <Route
          element={
            <DocumentTitle title="Not Found">
              <NotFound />
            </DocumentTitle>
          }
        />
      </Switch>
    </Suspense>
  );
}
