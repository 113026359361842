import React from "react";
import "./index.css";
//import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";

import { Amplify } from "aws-amplify";
import config from "./config";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { hydrate, render } from "react-dom";
import { createGenerateId, JssProvider, SheetsRegistry } from "react-jss";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.aws.cognito.REGION,
    userPoolId: config.aws.cognito.USER_POOL_ID,
    identityPoolId: config.aws.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.aws.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.aws.s3.REGION,
    bucket: config.aws.s3.BUCKET,
    identityPoolId: config.aws.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "box",
        endpoint: config.aws.apiGateway.BOX,
        region: config.aws.apiGateway.REGION,
      },
      {
        name: "group",
        endpoint: config.aws.apiGateway.GROUP,
        region: config.aws.apiGateway.REGION,
      },
      {
        name: "payment",
        endpoint: config.aws.apiGateway.PAYMENT,
        region: config.aws.apiGateway.REGION,
      },
      {
        name: "user",
        endpoint: config.aws.apiGateway.USER,
        region: config.aws.apiGateway.REGION,
      },
    ],
  },
});

// const container = document.getElementById("root");
// const root = createRoot(container);
// root.render(
//   <React.StrictMode>
//     <Router>
//       <App />
//     </Router>
//   </React.StrictMode>
// );

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <React.StrictMode>
//       <Router>
//         <App />
//       </Router>
//     </React.StrictMode>,
//     rootElement
//   );
// } else {
//   render(
//     <React.StrictMode>
//       <Router>
//         <App />
//       </Router>
//     </React.StrictMode>,
//     rootElement
//   );
// }

const rootElement = document.getElementById("root");
if (rootElement && rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>,
    rootElement,
    () => {
      const reactSnapStyles = document.getElementById("react-snap-styles");
      reactSnapStyles?.parentNode?.removeChild(reactSnapStyles);
    }
  );
} else {
  const registry = new SheetsRegistry();
  const generateId = createGenerateId();

  render(
    <JssProvider registry={registry} generateId={generateId}>
      <React.StrictMode>
        <Router>
          <App />
        </Router>
      </React.StrictMode>
    </JssProvider>,
    rootElement,
    () => {
      if (navigator.userAgent === "ReactSnap") {
        const badStyles = document.querySelectorAll("[data-jss]");
        badStyles.forEach((cssStyle) =>
          cssStyle.parentNode?.removeChild(cssStyle)
        );

        const style = document.createElement("style");
        style.innerHTML = registry.toString();
        style.setAttribute("id", "react-snap-styles");

        const head = document.querySelector("head");
        head.appendChild(style);
      }
    }
  );
}

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration?.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    }
  },
});
