const config = {
  aws: {
    s3: {
      REGION: "eu-west-1",
      BUCKET: process.env.REACT_APP_IMAGE_UPLOAD_BUCKET,
    },
    apiGateway: {
      REGION: "eu-west-1",
      BOX: process.env.REACT_APP_API + "box/",
      GROUP: process.env.REACT_APP_API + "group/",
      PAYMENT: process.env.REACT_APP_API + "payment/",
      USER: process.env.REACT_APP_API + "user/",
    },
    cognito: {
      REGION: "eu-west-1",
      USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
      APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
      IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
  },
  federated: {
    // googleClientId: "123",
    // facebookAppId: "123",
  },
  stripe: {
    publishable: process.env.REACT_APP_STRIPE_PUBLISHABLE,
  },
  imageRootAddress: process.env.REACT_APP_IMAGE_ROOT_ADDR,
  gtm: {
    id: process.env.REACT_APP_GTM_ID,
    environment: {
      gtm_auth: process.env.REACT_APP_GTM_AUTH,
      gtm_preview: process.env.REACT_APP_GTM_PREVIEW,
    },
  },
};

export default config;
