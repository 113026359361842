import { Avatar } from "@material-ui/core";
import { Photo } from "@material-ui/icons";
import config from "../config";

export default function GroupAvatar({ groupId }) {
  return (
    <Avatar
      variant="square"
      src={config.imageRootAddress + "group/" + groupId + ".png"}
    >
      <Photo fontSize="large" />
    </Avatar>
  );
}
