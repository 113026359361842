import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import {
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  SvgIcon,
} from "@material-ui/core";
import { LocationSearching, Search } from "@material-ui/icons";
import { mdiQrcodeScan } from "@mdi/js";

import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  outerGrid: {
    alignItems: "center",
    margin: theme.spacing(1),
  },
  noPadding: {
    padding: 0,
  },
  fieldPadding: {
    paddingTop: "1vh",
    paddingBottom: "1vh",
  },
  topMargin: {
    paddingTop: "2vh",
    marginTop: "4vh",
  },
  searchButton: {
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 300,
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
    },
  },
  logoContainer: {
    display: "block",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
    },
  },
  logo: {
    height: "150px",
    width: "auto",
    [theme.breakpoints.up("md")]: {
      height: "200px",
    },
  },
  strapline: {
    fontSize: "1rem",
    fontWeight: 300,
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem",
    },
  },
  textSizeAlign: {
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  boxForm: {
    marginTop: "1rem",
    display: "flex",
    alignItems: "center",
    height: "52px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  inputCodeEntered: {
    fontSize: 35,
    textAlign: "center",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  clipartAndTextGrid: {
    marginTop: "1rem",
    marginBottom: "0rem",
  },
  clipart: {
    height: "6em",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("sm")]: {
      height: "10em",
    },
  },
  text: {
    textAlign: "center",
    padding: "1rem",
    margin: "1rem",
  },
}));

export default function BoxNumber(props) {
  const classes = useStyles();
  const { handleCodeSubmit, handleQRClick, showNearest, code, onChange } =
    props;

  return (
    <Grid item xs={12}>
      <form onSubmit={handleCodeSubmit}>
        <Paper className={classes.boxForm}>
          <IconButton
            className={classes.iconButton}
            aria-label="Search Nearest"
            onClick={showNearest}
          >
            <LocationSearching />
          </IconButton>
          <InputBase
            type="number"
            className={classes.input}
            placeholder="Enter a Box Number"
            inputProps={{
              "aria-label": "Box Number",
              className: clsx({
                [classes.inputCodeEntered]: !!code.length,
              }),
            }}
            value={code}
            onChange={onChange}
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
          >
            <Search />
          </IconButton>
          {isMobile && (
            <>
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                className={classes.iconButton}
                aria-label="scan qr"
                onClick={handleQRClick}
              >
                <SvgIcon fontSize="large">
                  <path d={mdiQrcodeScan} />
                </SvgIcon>
              </IconButton>
            </>
          )}
        </Paper>
      </form>
    </Grid>
  );
}
