import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import {
  AppBar,
  Button,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AccountCircle } from "@material-ui/icons";

import { Auth } from "aws-amplify";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";

import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";
import { ReactComponent as Logo } from "./img/logo.svg";
import Routes from "./Routes.js";
import config from "./config";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import MUICookieConsent from "material-ui-cookie-consent";
import { HideBetween } from "react-hide-on-scroll";
import { Helmet, HelmetProvider } from "react-helmet-async";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "gold",
    color: "inherit",
    zIndex: 10,
    boxShadow: "none",
  },
  titleBar: {
    justifyContent: "flex-end",
    paddingLeft: 0,
    paddingRight: 0,
    color: "inherit",
  },
  titleBarTitle: {
    flexGrow: 1,
    textDecoration: "none",
    color: "inherit",
    fontWeight: 300,
  },
  appBarLogo: {
    height: "32px",
    width: "32px",
    color: "inherit",
  },
  profileIcon: {
    color: "black",
  },
  noXPaddingContainer: {
    backgroundColor: "gold",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  bottomAppBar: {
    backgroundColor: "gold",
    boxShadow: "none",
    top: "auto",
    bottom: 0,
  },
  privacyLink: {
    marginLeft: "auto",
  },
}));

const queryClient = new QueryClient();

export default function App() {
  const isReactSnap = navigator.userAgent === "ReactSnap";

  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const [isAuthenticating, setIsAuthenticating] = React.useState(true);
  const [isAuthenticated, userHasAuthenticated] = React.useState(false);

  const [auth, setAuth] = React.useState();

  const [stripePromise] = useState(async () => {
    const { loadStripe } = await import("@stripe/stripe-js");
    return loadStripe(config.stripe.publishable);
  });

  React.useEffect(
    () => {
      async function onLoad() {
        try {
          await Auth.currentSession();
          await Auth.currentAuthenticatedUser().then(setAuth);
          userHasAuthenticated(true);
        } catch (e) {
          if (
            e.message === "Refresh Token has been revoked" ||
            e.message === "Refresh Token has expired"
          ) {
            console.log("Refresh token expired, redirecting to login");
            navigate("/login");
          } else if (e !== "No current user") {
            onError(e);
          }
        }
        setIsAuthenticating(false);
      }

      onLoad();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <GTMProvider state={config.gtm}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen />
          <AppBar position="sticky" className={classes.appBar}>
            <Container maxWidth="sm" className={classes.noXPaddingContainer}>
              <Toolbar className={classes.titleBar}>
                {location.pathname === "/" && (
                  <HideBetween height startHeight={0} endHeight={70}>
                    <IconButton onClick={() => navigate("/")}>
                      <Logo className={classes.appBarLogo} />
                    </IconButton>
                    <Typography
                      to="/"
                      component={Link}
                      variant="h6"
                      className={classes.titleBarTitle}
                    >
                      Honesty Box
                    </Typography>
                  </HideBetween>
                )}
                {location.pathname !== "/" && (
                  <>
                    <IconButton onClick={() => navigate("/")}>
                      <Logo className={classes.appBarLogo} />
                    </IconButton>
                    <Typography
                      to="/"
                      component={Link}
                      variant="h6"
                      className={classes.titleBarTitle}
                    >
                      Honesty Box
                    </Typography>
                  </>
                )}
                {location.pathname !== "/login" &&
                  !isAuthenticating &&
                  (isAuthenticated && auth ? (
                    <IconButton
                      className={classes.profileIcon}
                      color="inherit"
                      onClick={() => navigate("/profile")}
                    >
                      <AccountCircle />
                    </IconButton>
                  ) : (
                    <Button color="inherit" onClick={() => navigate("/login")}>
                      Login / SignUp
                    </Button>
                  ))}
              </Toolbar>
            </Container>
          </AppBar>
          <Container maxWidth="sm" className={classes.noXPaddingContainer}>
            <AppContext.Provider
              value={{
                stripePromise,
                isAuthenticated,
                userHasAuthenticated,
                auth,
                setAuth,
              }}
            >
              <HelmetProvider>
                <Helmet>
                  <title>Honesty Box</title>
                  <meta
                    name="description"
                    content="A modern take on a traditional system of payment, helping people to make and receive cash-less payments for all sorts of purposes."
                  />
                  <meta property="og:site_name" content="Honesty Box" />
                  <meta
                    property="og:title"
                    content="Simple cash-free payments"
                  />
                  <meta property="og:type" content="website" />
                  <meta
                    property="og:description"
                    content="A modern take on a traditional system of payment, helping people to make and receive cash-less payments for all sorts of purposes."
                  />
                  <meta
                    property="og:url"
                    content={"https://honestybox.org" + location.pathname}
                  />
                  <meta
                    property="og:image"
                    content="%PUBLIC_URL%/ogImage.png"
                  />
                </Helmet>
                <Routes />
              </HelmetProvider>
            </AppContext.Provider>
          </Container>
          <Container
            maxWidth="sm"
            className={classes.noXPaddingContainer}
            style={{ paddingTop: "3rem" }}
          >
            <Toolbar variant="dense" disableGutters>
              {location.pathname !== "/" && (
                <Button
                  size="small"
                  href="/about"
                  onClick={() => navigate("/about")}
                >
                  About
                </Button>
              )}
              <Button
                size="small"
                className={classes.privacyLink}
                href="/privacy"
                onClick={() => navigate("/privacy")}
              >
                Privacy
              </Button>
              <Button
                size="small"
                href="/terms"
                onClick={() => navigate("/terms")}
              >
                Terms
              </Button>
            </Toolbar>
          </Container>
        </QueryClientProvider>
      </GTMProvider>

      {!isReactSnap && (
        <MUICookieConsent
          cookieName="CookieAcceptance"
          title="Cookies"
          message="This site uses cookies to control how you log in and to help process payments. See the privacy link at the bottom of the page for full details."
          componentType="Dialog"
        />
      )}
    </>
  );
}
