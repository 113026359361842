import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import {
  Button,
  Box,
  CircularProgress,
  Container,
  Drawer,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from "@material-ui/core";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { onError } from "../libs/errorLib.js";

import { ReactComponent as WireLogo } from "../img/logo.svg";

import BoxNumberInput from "../components/BoxNumberInput.js";
import GroupAvatar from "../components/GroupAvatar.js";
import piggy from "../img/undraw_piggy.svg";
import fitness from "../img/undraw_fitness_stats.svg";
import countryside from "../img/john-mark-strange-u5w0MX2pwlg-unsplash.jpg";
import produce from "../img/ralph-ravi-kayden-VnIVONGhie8-unsplash.jpg";
import campervans from "../img/tobias-tullius-G-cN813AGEk-unsplash.jpg";

import visa from "../img/visa.svg";
import mastercard from "../img/mc_symbol.svg";
import googlepay from "../img/Google_Pay_Acceptance_Mark.svg";
import applepay from "../img/Apple_Pay_Mark_RGB_041619.svg";

import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useQueryClient } from "react-query";

const useStyles = makeStyles((theme) => ({
  outerGrid: {
    alignItems: "center",
    margin: theme.spacing(1),
  },
  noPadding: {
    padding: 0,
  },
  fieldPadding: {
    paddingTop: "1vh",
    paddingBottom: "1vh",
  },
  topMargin: {
    paddingTop: "2vh",
    marginTop: "4vh",
  },
  searchButton: {
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  title: {
    fontSize: "2rem",
    fontWeight: 300,
    textAlign: "left",
    [theme.breakpoints.up("sm")]: {
      fontSize: "3rem",
    },
  },
  logoContainer: {
    display: "block",
    textAlign: "right",
  },
  logo: {
    height: "4rem",
    width: "auto",
    [theme.breakpoints.up("sm")]: {
      height: "6rem",
    },
  },
  subtitle: {
    fontSize: "1.5rem",
    fontWeight: 300,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
    },
  },
  boxForm: {
    marginLeft: "1rem",
    marginRight: "1rem",
    marginTop: "1rem",
    //padding: "4px 4px",
    display: "flex",
    alignItems: "center",
    height: "52px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  inputCodeEntered: {
    fontSize: 35,
    textAlign: "center",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  clipartAndTextGrid: {
    marginTop: "1rem",
    marginBottom: "0rem",
  },
  clipart: {
    height: "6em",
    width: "auto",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("sm")]: {
      height: "10em",
    },
  },
  paymentMethod: {
    height: "2em",
    width: "auto",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  text: {
    textAlign: "center",
    padding: "1rem",
    //margin: "1rem",
  },
  cardPictures: {
    marginTop: "16px",
    marginBottom: "16px",
  },
  strongBody: {
    fontWeight: 500,
  },
}));

export default function Home() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [nearbyBoxes, setNearbyBoxes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    if (nearbyBoxes?.length) {
      nearbyBoxes.forEach((box) => {
        queryClient.setQueryData(["box", box.boxId], box);
      });
    }
  }, [nearbyBoxes, queryClient]);

  const handleCodeSubmit = (event) => {
    event.preventDefault();
    if (code !== "") {
      sendDataToGTM({ event: "select_content", content_type: "box_number" });
      navigate("/" + code);
    } else {
      return false;
    }
  };

  const handleQRClick = () => {
    sendDataToGTM({ event: "select_content", content_type: "qr_scan" });
    navigate("/scan");
  };

  const showNearest = async () => {
    if (!navigator.geolocation) {
      onError("Geolocation is not supported by your browser");
    } else {
      setIsLoading(true);
      setDrawerOpen(true);
      sendDataToGTM({ event: "select_content", content_type: "show_nearest" });
      navigator.geolocation.getCurrentPosition(
        (position) => {
          API.post("box", "search", {
            body: {
              radius: 50000,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          }).then(
            (body) => {
              setNearbyBoxes(body);
              setIsLoading(false);
            },
            (error) => {
              onError(error);
              setIsLoading(false);
              setDrawerOpen(false);
            }
          );
        },
        (err) => {
          onError(err);
          setIsLoading(false);
          setDrawerOpen(false);
        }
      );
    }
  };

  const exampleImages = [
    {
      image: countryside,
      alt: "Countryside",
      title: "Donations for conservation",
      link: "/247",
    },
    {
      image: campervans,
      alt: "Campervans",
      title: "Parking or camping fees",
      link: "/644",
    },
    {
      image: produce,
      alt: "Vegetables",
      title: "Produce for sale",
      link: "/424",
    },
  ];

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid item className={classes.logoContainer}>
          <WireLogo className={classes.logo} />
        </Grid>
        <Grid item>
          <Typography
            variant="h1"
            className={clsx(classes.title, classes.textSizeAlign)}
          >
            Honesty Box
          </Typography>
          <Typography variant="subtitle1">Simple cash-free payments</Typography>
        </Grid>
      </Grid>
      <BoxNumberInput
        onChange={(event) => setCode(event.target.value)}
        handleCodeSubmit={handleCodeSubmit}
        handleQRClick={handleQRClick}
        showNearest={showNearest}
        code={code}
      />

      <Box className={classes.text}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/about")}
        >
          Learn more
        </Button>
      </Box>

      <Paper className={classes.topMargin}>
        <Grid
          container
          justifyContent="space-around"
          alignItems="center"
          className={classes.clipartAndTextGrid}
        >
          <Grid item>
            <Typography variant="h2" className={classes.subtitle} paragraph>
              Host your own
              <br />
              Honesty box
            </Typography>
          </Grid>
          <Grid item>
            <img src={piggy} alt="Piggy Bank" className={classes.clipart} />
          </Grid>
        </Grid>
        <Box className={classes.text}>
          <Typography variant="body1" paragraph>
            Loose change isn't in as many pockets as it used to be.
          </Typography>
          <Typography variant="body1" paragraph>
            Honesty Box makes cash-free payments simple.
          </Typography>
          <Grid
            container
            justifyContent="center"
            spacing={1}
            alignItems="center"
            className={classes.cardPictures}
          >
            <Grid item>
              <img src={visa} alt="Visa" className={classes.paymentMethod} />
            </Grid>
            <Grid item>
              <img
                src={mastercard}
                alt="Mastercard"
                className={classes.paymentMethod}
              />
            </Grid>
            <Grid item>
              <img
                src={applepay}
                alt="Apple Pay"
                className={classes.paymentMethod}
              />
            </Grid>
            <Grid item>
              <img
                src={googlepay}
                alt="Google Pay"
                className={classes.paymentMethod}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/about");
              return false;
            }}
          >
            Learn more
          </Button>
        </Box>
      </Paper>

      <Paper className={classes.topMargin}>
        <Grid
          container
          justifyContent="space-around"
          alignItems="center"
          className={classes.clipartAndTextGrid}
        >
          <Grid item>
            <img src={fitness} alt="Runner" className={classes.clipart} />
          </Grid>
          <Grid item>
            <Typography variant="h2" className={classes.subtitle} paragraph>
              Get started quickly
              <br />
              and easily today
            </Typography>
          </Grid>
        </Grid>
        <Box className={classes.text}>
          <Typography variant="body1" paragraph>
            Sign-up in minutes to create your first box.
          </Typography>
          <Typography variant="body1" paragraph>
            Add some simple text, and upload photos straight from your phone or
            computer.
          </Typography>
          <Typography variant="body1" paragraph>
            Download custom QR codes to print and display, or to add to your own
            existing signs.
          </Typography>
          <Typography variant="body1" paragraph>
            No up-front fees, and no equipment to purchase or install.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/about");
              return false;
            }}
          >
            Learn more
          </Button>
        </Box>
      </Paper>

      <Box className={clsx(classes.topMargin, classes.text)}>
        <Typography variant="h2" className={classes.subtitle} align="center">
          Who is using Honesty Box?
        </Typography>
        <Typography variant="body2">
          Click on the pictures below to see some of our favourite boxes
        </Typography>
      </Box>

      <ImageList className={classes.topMargin} cols={1}>
        {exampleImages.map((example) => (
          <ImageListItem
            onClick={() => navigate(example.link)}
            component="a"
            href={example.link}
          >
            <img src={example.image} alt={example.alt} />
            <ImageListItemBar title={example.title} />
          </ImageListItem>
        ))}
      </ImageList>

      <Box className={classes.text}>
        <Button
          variant="contained"
          color="primary"
          href="/about"
          onClick={() => navigate("/about")}
        >
          Learn more
        </Button>
      </Box>

      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Container maxWidth="sm">
          <List subheader={<ListSubheader>Nearby Boxes</ListSubheader>}>
            {isLoading ? (
              <center>
                <CircularProgress />
              </center>
            ) : nearbyBoxes.length ? (
              nearbyBoxes
                .sort((a, b) => a.distance - b.distance)
                .map((box) => (
                  <ListItem
                    button
                    key={box.boxId}
                    onClick={() => navigate("/" + box.boxId)}
                  >
                    <ListItemAvatar>
                      <GroupAvatar groupId={box.groupId} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={box.boxId + " : " + box.name}
                      secondary={box.distance.toFixed(1) + " km"}
                    />
                  </ListItem>
                ))
            ) : (
              <ListItem>
                <Typography>
                  No boxes found nearby, try using a box number
                </Typography>
              </ListItem>
            )}
          </List>
        </Container>
      </Drawer>
    </>
  );
}
